<template>
  <stack-item v-if="group.flag === 1">
  <contents-box type="inner">
    <div :class="$style.group_detail">
      <div :class="$style.group_detail_title">{{group.label}}</div>

      <div
        :class="$style.group_flex_elem"
        v-if="user.account_type > 10">
        <div :class="$style.space_left">
          <p :class="$style.kana">所属人数：</p>
          <p>{{userCount}}名</p>
        </div>
        <div :class="$style.space_left">
          <p :class="$style.kana">作成者：</p>
          <p v-if="(group.createUser.customer_id && group.createUser.account_type <= 10)">{{group.createUser.customer_id}}</p>
          <p v-else-if="[11, 12].includes(group.createUser.account_type)">{{ isCompany ? '企業' : '学校' }}管理者（{{ user.account_type === 11 ? '主' : '副' }}）</p>
          <p v-else-if="group.createUser.account_type > 30">事務局</p>
        </div>
        <div :class="$style.space_left">
          <p :class="$style.kana">{{group.createUser.kana}}</p>
          <p>{{group.createUser.username}}</p>
        </div>
      </div>
      <div :class="$style.group_detail_edits">
        <div v-if="!group.detailOpen">
          <i class="fa-regular fa-plus" :class="$style.group_detail_icon" @click="toggleGroupFlag(group.id)"></i>
        </div>
        <div v-if="group.detailOpen">
          <i class="fa-solid fa-pen-to-square" :class="[$style.group_detail_icon, $style.space_right]" @click="openGroupEditor(group.id)"></i>
          <i class="fa-solid fa-trash-can" :class="[$style.group_detail_icon, $style.space_right_l]" @click="deleteGrpoup(group.id)"></i>
          <i class="fa-regular fa-minus" :class="$style.group_detail_icon" @click="toggleGroupFlag(group.id)"></i>
        </div>
      </div>
    </div>

    <div
      v-if="group.editFlag"
      :class="$style.group_editor">
      <spacer :y="5" />

      <unit-editor
        :user="user"
        :school="user.account_type >= 30 ? school : user.school"
        :users="cloneDeep(users)"
        :registFlag="1"
        :unit="group"
        :hideDelete="true"
        @pushCancel="openGroupEditor(group.id)"
        @registGroup="$emit('getGroupList', group.id)" />
    </div>

    <div v-if="group.detailOpen">
      <loader-simple :flag="flag.loader.user">
        <div v-if="group.userList && !flag.loader.user">
          <spacer v-if="group.userList.length" :y="2"/>
          <stack-item space="2"
            v-for="userData of group.userList"
            :key="userData">
            <contents-box type="inner" :class="$style.user_flex">
              <div :class="$style.user">
                <div :class="$style.user_kana"><router-link :to="`${historyPath}/${userData.user[0].id}/`" target="_blank">{{userData.user[0].kana}}</router-link></div>
                  <div :class="$style.user_name"><router-link :to="`${historyPath}/${userData.user[0].id}/`" target="_blank" v-html="userData.user[0].username"></router-link>
                  <span v-if="userData.user[0].age"> ({{userData.user[0].age}})</span>
                  <span v-if="hasCocomoni(userData.user[0])" :class="$style.user_cocomoniscore">
                    <router-link :to="`${cocomoniPath}/${userData.user[0].id}/`" target="_blank"><img :src="`/img/default/${cocomoniData(userData.user[0]).img}`" :class="$style.user_cocomoniicon"></router-link>
                  </span>
                </div>
              </div>
              <template v-slot:btn :class="$style.deletebtn">
                <basic-btn
                  tag="button"
                  size="sm"
                  addClass="danger"
                  @click="removeUser($event, {
                    user: userData.user[0],
                    unit: group,
                  })">削除</basic-btn>
              </template>
            </contents-box>
          </stack-item>
        </div>
      </loader-simple>
    </div>
  </contents-box>
  <spacer :y="2"/>
</stack-item>
</template>

<script>
import { mapState } from 'vuex';
import BasicBtn from '@/views/components/BasicBtn.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import Spacer from '@/views/components/Spacer.vue';
import UnitEditor from '@/views/components/UnitEditor';
import StackItem from '@/views/components/StackItem.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import cf from '@/mixins/commonFunctions.js';
import { cloneDeep } from 'lodash';

export default {
  name: 'group-list',
  mixins: [cf],
  components: {
    BasicBtn,
    ContentsBox,
    Spacer,
    UnitEditor,
    StackItem,
    LoaderSimple,
  },
  props: {
    flag: {
      type: Object,
    },
    groups: {
      type: Array,
    },
    group: {
      type: Object,
    },
    users: {
      type: Array,
    },
    user: {
      type: Object,
    },
    isCompany: {
      type: Boolean,
    },
    school: {
      type: Object,
    },
  },
  data() {
    return {
      historyPath: null,
      cocomoniPath: null,
      cloneDeep,
    };
  },
  created() {
    // account_typeに応じてパス変動
    switch (this.user.account_type) {
      case 11: // 学校管理者
        this.historyPath = '/teacher/history';
        this.cocomoniPath = '/teacher/cocomoni';
        break;
      case 31: // 事務局
        this.historyPath = '/operator/history';
        this.cocomoniPath = '/operator/cocomoni';
        break;
      default: // 利用者（教職員）
        this.historyPath = '/cocomoni';
        this.cocomoniPath = '/cocomoni';
        break;
    }
  },
  computed: {
    ...mapState(['helper', 'user']),
    // 所属ユーザ数
    userCount() {
      return this.group.users.length;
    },
  },
  methods: {
    /** ローディング表示 */
    showLoading() {
      const args = { modalName: 'modalLoadingBallScaleRippleMultiple' };
      this.$store.dispatch('modal/loadings/showModal', args, { root: true });
    },
    /** ローディング非表示 */
    hideLoading() {
      this.$store.dispatch('modal/loadings/hideModal', null, { root: true });
    },

    // ココモニ有無判定
    hasCocomoni(user) {
      let result = false;
      const userId = isNaN(user.id) ? user.rawId : user.id;
      if (this.user.cocomoni[userId]) {
        result = true;
      }
      return result;
    },

    // ココモニ矢印取得
    cocomoniData(user) {
      let result = {};
      const userId = isNaN(user.id) ? user.rawId : user.id;
      if (this.user && this.user.cocomoni && this.user.cocomoni[userId]) {
        if (this.user.cocomoni[userId] >= 7) {
          result = {
            stenScore: this.user.cocomoni[userId],
            text: 'High',
            img: 'arrow_up.png',
            alt: '上向き矢印',
          };
        } else if (this.user.cocomoni[userId] < 7 && this.user.cocomoni[userId] >= 3) {
          result = {
            stenScore: this.user.cocomoni[userId],
            text: 'Middle',
            img: 'arrow_flat.png',
            alt: '横ばい矢印',
          };
        } else {
          result = {
            stenScore: this.user.cocomoni[userId],
            text: 'Low',
            img: 'arrow_down.png',
            alt: '下向き矢印',
          };
        }
      }
      return result;
    },

    // 編集フラグ更新
    toggleGroupFlag(id) {
      const target = this.groups.find((group) => group.id === id);
      if (!target.detailOpen) {
        target.detailOpen = true;
        this.getGroupDetail(target.id);
      } else if (target.editFlag) {
        target.detailOpen = !target.detailOpen;
        target.editFlag = false;
      } else {
        target.detailOpen = !target.detailOpen;
      }
    },

    // グループ編集画面開き
    openGroupEditor(id) {
      this.groups.forEach((group) => {
        if (group.id === id) {
          group.editFlag = !group.editFlag;
        } else {
          // 選択したグループ以外の編集フラグをfalseにする
          group.editFlag = false;
        }
      });
    },

    // グループ削除
    async deleteGrpoup(id) {
      let schoolId;
      if (this.school) { // 事務局の場合はschoolをもらっているはず
        schoolId = this.school.id;
      } else if (this.user.account_type === 11 || this.user.account_type === 3) { // 学校管理者/教職員
        schoolId = this.user.school[0].id;
      } else {
        return; // 上記以外の場合は処理を抜ける
      }
      if (!confirm(`グループ名 ${this.group.label} を削除します。よろしいですか？`)) {
        return;
      }
      const data = {
        school_id: schoolId,
        id,
        flag: 999,
        update_user_id: this.user.id,
        newUsers: [],
      };
      try {
        await this.axios({
          method: 'POST',
          url: '/v1/unit/set/updater',
          data,
        });
        // emitで親コンポーネントに通知
        this.$emit('getGroupList', id);
      } catch (error) {
        alert('グループ削除に失敗しました');
        throw error;
      }
    },

    /** グループ単体取得ではグループに属するユーザー詳細も取得 */
    getGroupDetail(id) {
      const target = this.groups.find((group) => group.id === id);
      const params = { id };
      this.axios({
        method: 'GET',
        url: '/v1/unit/get/detail',
        params,
      })
        .then((response) => {
          target.userList = response.data.data.userList;
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },

    // グループから利用者削除
    removeUser(e, args) {
      e.preventDefault();
      const confirmation = confirm(`${args.unit.label}から${args.user.username}さんを削除します。よろしいですか？`);
      if (!confirmation) return true;

      const data = {
        user_id: args.user.id,
        unit_id: args.unit.id,
      };

      this.axios({
        method: 'POST',
        url: '/v1/unit/set/deleter',
        data,
      })
        .then((response) => {
          const res = response.data.data;
          if (data.user_id === res.user_id
            && data.unit_id === res.unit_id) {
            alert('グループからユーザーを削除しました');
            this.$emit('getGroupList', res.unit_id);
          }
        })
        .catch((error) => {
          if (error.message) console.log(error.message);
          else console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" module>
.space {
  &_left {
    margin-left: 10px;
  }
  &_right {
    margin-right: 10px;
  }
  &_right_l {
    margin-right: 30px;
  }
}
.group_detail {
  display: flex;
  vertical-align: middle;
  align-items: center;
  justify-content: space-between;
  &_title {
    font-size: 20px;
    font-weight: bold;
  }
  &_edits {
    cursor: pointer;
  }
  &_icon {
    color: var(--gray-side);
    font-size: 20px;
  }
}
.group_flex_elem {
  display: flex;
  margin-top: -.2em;
  margin-left: 15px;
  flex: 1;
  align-items: center;
  font-size: .9em;
  @include sm-view {
    display: block;
    font-size: .8em;
  }
}
.kana {
  font-size: .5em;
}
.user {
  &_flex {
    display: flex;
    align-items: center;
  }
  &_kana {
    margin-bottom: -3px;
    font-size: .6em;
    a {
      color: black;
    }
  }
  &_name {
    font-size: 1.1em;
    a {
      color: black;
    }
  }
  &_cocomoniicon {
    display: inline-block;
    margin: 0 0 -3px 3px;
    width: 35px;
  }
}
.deletebtn {
  margin-left: auto;
}
</style>
