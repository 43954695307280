<template>
  <div>
    <spacer :y="5"/>
    <contents-box
      title="高ストレス者"
      icon="<i class='fa-solid fa-image-user'></i>"
      :guideIcon="{
        icon: 'fa-regular fa-circle-question',
        boxSize: 350,
        hoverText: '直近2週間以内にココロラインを下回ったユーザー',
        noTransform: true,
      }"
    >
      <loader-simple :flag="flag.loading.kokoroAlerts">
        <template v-if="!flag.loading.kokoroAlerts">
          <p><span :class="$style.userNum">{{ kokoroAlerts.length }}</span> 名</p>
          <table
             v-if="kokoroAlerts.length > 0"
            :class="$style.cocomoni_tbl"
          >
            <thead>
              <tr>
                <th v-if="!isCompany"><!-- 学年 --></th>
                <th><!-- 氏名 --></th>
                <th
                  v-for="date in displayDates"
                  v-bind:key="date"
                >{{ date }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="data in kokoroAlerts"
                v-bind:key="data.user_id"
              >
                <td v-if="!isCompany"><label :class="$style.grade">{{ _getGrade(data) }}</label></td>
                <td :class="$style.kokoro_alert_user">
                  <a @click="clickKokoroAlertUser(data.user_id)">{{ data.username }}</a>
                </td>
                <td
                  v-for="row in data.stenScores"
                  v-bind:key="row.mimosys_id"
                >
                  <template v-if="row.stenScore">
                    <img
                      :src="row.imageData.src"
                      :alt="row.imageData.alt"
                    >
                  </template>
                  <template v-else>
                    <p>-</p>
                  </template>
                </td>
              </tr>
            </tbody>
          </table>
        </template>
      </loader-simple>

      <spacer :y="3"/>
      <h3 :class="$style.dashboardContentsTitle">
        <i
          class='fa-solid fa-microphone'
          :class="$style.titleIcon"
          ></i>ココモニボイス利用推移
      </h3>
      <spacer :y="3"/>

      <div
        v-if="!getAllData"
        :class="$style.searchArea"
      >
        <select
          :class="$style.select_box"
          v-model="searchCondition.uranaiVoice.categorizeType"
          v-on:change="changeSearchCategoryType('uranaiVoice')"
        >
          <option value="">{{ schoolLabel }}全体</option>
          <option
            v-if="config.maxSchoolYear > 0"
            :value="CATEGORIZE_TYPE.SCHOOL_YEAR">学年別</option>
          <option
            :value="CATEGORIZE_TYPE.UNITS">グループ別</option>
        </select>

        <template v-if="searchCondition.uranaiVoice.categorizeType">
          <spacer :x="1"/>
          <select
            :class="$style.select_box"
            v-model="searchCondition.uranaiVoice.categorizeKey"
            v-on:change="changeSearchCategoryKey('uranaiVoice')"
          >
            <option
              v-for="opt in searchCondition.uranaiVoice.categorizeKeyOptions"
              :key="opt.value"
              :value="opt.value">{{ opt.label }}</option>
          </select>
        </template>
      </div>

      <spacer :y="2" />
      <Line
        :chart-options="lineGraph.uranaiVoice.options"
        :chart-data="lineGraph.uranaiVoice.data"
        css-classes="graphStyle"
        width="400"
        height="200"
        :class="$style['col-9']"
      />
    </contents-box>

    <spacer :y="5"/>
    <contents-box
      title="ココロの健康診断"
      icon="<i class='fa-solid fa-monitor-waveform'></i>"
    >

      <template v-if="school">
        <spacer :y="1"/>
        <MonitoringSchedules
          :school="school"
        />
        <spacer :y="1"/>
        <hr>
        <spacer :y="4"/>
      </template>

      <div :class="$style.searchArea">
        <select
          :class="[$style.select_box, $style.labelWidth]"
          v-model="searchCondition.kokoroKenkoShindan.academicYear"
          v-on:change="getGraphData('kokoroKenkoShindan')"
        >
          <option
            v-for="year in academicYearList4Monitoring"
            :key="year"
            :value="year">{{ year }}年度</option>
        </select>

        <div :class="$style.separator" />
        <!-- チェックボックスで、メインとなるグラフを表示するか制御する -->
        <input
          type="checkbox"
          id="showMonitoringMainData"
          v-model="flag.showMonitoringMainData"
        />
        <label for="showMonitoringMainData">ココロの健康診断 月別平均を表示する</label>

        <div
          v-if="!getAllData"
          :class="[
            $style.search_button,
            $style.right,
          ]"
        >
          <basic-btn
            tag="button"
            size="sm"
            @click="showUserChart()"><i class="fa-regular fa-user-chart" /> 個人チャート</basic-btn>
        </div>

      </div>

      <!-- クロス分析 -->
      <spacer :y="3" />
      <label-box
        label="クロス分析"
        color="gray"
        size="sm"
        :class="$style.label_max"
      />
      <spacer :y="1" />

      <ul :class="$style.detail">
        <li>
          <label-box
            label="年度"
            color="gray"
            size="sm"
            :class="$style.label"
          />
          <div :class="$style.width100">
            <div>
              <input
                type="checkbox"
                id="academic_year_all"
                :checked="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACADEMIC_YEAR].length === 0"
                :disabled="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACADEMIC_YEAR].length === 0"
                @change="clickAllCrossAnalytics($event, CATEGORIZE_TYPE.ACADEMIC_YEAR)"
              />
              <label for="academic_year_all">指定なし</label>
            </div>
            <div :class="$style.flexRow">
              <div
                v-for="academicYear in academicYearList4CrossAnalytics"
                :key="academicYear"
              >
                <input
                  type="checkbox"
                  :id="`${CATEGORIZE_TYPE.ACADEMIC_YEAR}_${academicYear}`"
                  :value="academicYear"
                  v-model="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACADEMIC_YEAR]"
                  @change="clickCrossAnalytics()"
                />
                <label :for="`${CATEGORIZE_TYPE.ACADEMIC_YEAR}_${academicYear}`">{{ academicYear }}年度</label>
              </div>
            </div>
          </div>
        </li>

        <li>
          <label-box
            label="相談方法"
            color="gray"
            size="sm"
            :class="$style.label"
          />
          <div :class="$style.width100">
            <div>
              <input
                type="checkbox"
                id="who5answer_type_all"
                :checked="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.WHO5ANSWER_TYPE].length === 0"
                :disabled="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.WHO5ANSWER_TYPE].length === 0"
                @change="clickAllCrossAnalytics($event, CATEGORIZE_TYPE.WHO5ANSWER_TYPE)"
              />
              <label for="who5answer_type_all">すべての健康相談</label>
            </div>
            <div :class="$style.flexRow">
              <div
                v-for="[key, value] in who5AnswerTypesForCrossAnalytics"
                :key="key"
              >
                <input
                  type="checkbox"
                  :id="`${CATEGORIZE_TYPE.WHO5ANSWER_TYPE}_${key}`"
                  :value="key"
                  v-model="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.WHO5ANSWER_TYPE]"
                  @change="clickCrossAnalytics()"
                />
                <label :for="`${CATEGORIZE_TYPE.WHO5ANSWER_TYPE}_${key}`">{{ value }}</label>
              </div>
            </div>
          </div>
        </li>

        <li v-if="config.maxSchoolYear > 0">
          <label-box
            label="学年"
            color="gray"
            size="sm"
            :class="$style.label"
          />
          <div>
            <div>
              <input
                type="checkbox"
                :id="`${CATEGORIZE_TYPE.SCHOOL_YEAR}_all`"
                :checked="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.SCHOOL_YEAR].length === 0"
                :disabled="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.SCHOOL_YEAR].length === 0"
                @change="clickAllCrossAnalytics($event, CATEGORIZE_TYPE.SCHOOL_YEAR)"
              />
              <label :for="`${CATEGORIZE_TYPE.SCHOOL_YEAR}_all`">全学年</label>
            </div>
            <div :class="$style.flexRow">
              <div v-for="schoolYear in config.maxSchoolYear" :key="schoolYear">
                <input
                  type="checkbox"
                  :id="`${CATEGORIZE_TYPE.SCHOOL_YEAR}_${schoolYear}`"
                  :value="schoolYear"
                  v-model="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.SCHOOL_YEAR]"
                  @change="clickCrossAnalytics()"
                />
                <label :for="`${CATEGORIZE_TYPE.SCHOOL_YEAR}_${schoolYear}`">{{ schoolYear }}年生</label>
              </div>
            </div>
          </div>
        </li>

        <li v-if="config.units.length > 0">
          <label-box
            label="グループ"
            color="gray"
            size="sm"
            :class="$style.label"
          />
          <div>
            <div>
              <input
                type="checkbox"
                :id="`${CATEGORIZE_TYPE.UNITS}_all`"
                :checked="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.UNITS].length === 0"
                :disabled="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.UNITS].length === 0"
                @change="clickAllCrossAnalytics($event, CATEGORIZE_TYPE.UNITS)"
              />
              <label :for="`${CATEGORIZE_TYPE.UNITS}_all`">全グループ</label>
            </div>
            <div :class="$style.flexRow">
              <div v-for="unit in config.units" :key="unit.id">
                <input
                  type="checkbox"
                  :id="`${CATEGORIZE_TYPE.UNITS}_${unit.id}`"
                  :value="unit.id"
                  v-model="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.UNITS]"
                  @change="clickCrossAnalytics()"
                />
                <label :for="`${CATEGORIZE_TYPE.UNITS}_${unit.id}`">{{ unit.label }}</label>
              </div>
            </div>
          </div>
        </li>

        <li v-if="isSpotPlan">
          <label-box
            label="種別"
            color="gray"
            size="sm"
            :class="$style.label"
          />
          <div>
            <div>
              <input
                type="checkbox"
                :id="`${CATEGORIZE_TYPE.ACCOUNT_TYPE}_all`"
                :checked="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACCOUNT_TYPE].length === 0"
                :disabled="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACCOUNT_TYPE].length === 0"
                @change="clickAllCrossAnalytics($event, CATEGORIZE_TYPE.ACCOUNT_TYPE)"
              />
              <label :for="`${CATEGORIZE_TYPE.ACCOUNT_TYPE}_all`">全利用者</label>
            </div>
            <div :class="$style.flexRow">
              <div v-for="accountType in crossAnalyticsAccountTypes" :key="accountType">
                <input
                  type="checkbox"
                  :id="`${CATEGORIZE_TYPE.ACCOUNT_TYPE}_${accountType}`"
                  :value="accountType"
                  v-model="searchCondition.kokoroKenkoShindan.crossAnalytics[CATEGORIZE_TYPE.ACCOUNT_TYPE]"
                  @change="clickCrossAnalytics()"
                />
                <label :for="`${CATEGORIZE_TYPE.ACCOUNT_TYPE}_${accountType}`">{{ accountTypeLabel[accountType] }}</label>
              </div>
            </div>
          </div>
        </li>
      </ul>

      <!-- <div :class="$style.kokoroKenkoShindanSearch">
        <dl>
          <dt>年度</dt>
          <dd></dd>
        </dl>
        <dl>
          <dt>全体</dt>
          <dd></dd>
        </dl>
        <dl v-if="config.maxSchoolYear > 0">
          <dt>学年</dt>
          <dd :style="$style.radioWrapper">
            <div
              v-for="schoolYear in config.maxSchoolYear"
              :key="schoolYear"
            >{{ schoolYear }}年</div>
          </dd>
        </dl>
        <dl>
          <dt>グループ</dt>
          <dd></dd>
        </dl>
      </div> -->

      <loader-simple :flag="flag.loading.kokoroKenkoShindan">
        <tamplate v-if="!flag.loading.kokoroKenkoShindan">
          <Line
            :chart-options="lineGraph.kokoroKenkoShindan.options"
            :chart-data="lineGraph.kokoroKenkoShindan.data"
            css-classes="graphStyle"
            width="400"
            height="200"
            :class="$style['col-9']"
          />

          <spacer :y="2" />

          <h3 :class="$style.dashboardContentsTitle">
            <i
              class='fa-solid fa-calendar'
              :class="$style.titleIcon"
              ></i>ココロの健康診断 月別集計
          </h3>
          <spacer :y="1"/>
          <div :class="$style.kokoro_counseling_summary">
            <table>
              <tbody>
                <tr>
                  <td
                    v-for="(row, i) in answerRates"
                    v-bind:key="i"
                  >
                    <p v-if="row">{{ `${row.numerator}/${row.denominator}名` }}</p>
                    <template v-if="row">
                      <span :class="$style.bold">{{ row.rate }}</span>%
                    </template>
                    <span v-else>-</span>
                  </td>
                </tr>
                <tr>
                  <td
                    v-for="(data, i) in needHelpMonths"
                    v-bind:key="data.month"
                    :class="[
                      data.users.length > 0 ? $style.need_help_user_link : null,
                      needHelpUserTargetMonth === data.month ? $style.selectedCell : null,
                    ]"
                    v-on:click="clickNeedHelpUserCell(data)"
                  >
                    <span :class="$style.bold">{{ data.users.length }}</span>名
                    <p>{{ monitoringAverages[i].month }}</p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p>上段：回答率 ／ 下段：要支援者</p>

            <loader-simple :flag="flag.loading.needHelpUserDetails">
              <div v-if="!flag.loading.needHelpUserDetails && needHelpUserDetails.length > 0">
                <spacer :y="3" />
                <div :class="$style.flex">
                  <h4>要支援者</h4>
                  <spacer y="1" />
                  <GuideIcon
                    icon='fa-regular fa-circle-question'
                    boxSize='330'
                    hoverText='ココロの健康診断のスコアが５未満のユーザー'
                  />
                </div>
                <dl :class="$style.need_help_user_row">
                  <dd
                    v-for="user in needHelpUserDetails"
                    v-bind:key="user.id"
                  >
                    <div
                      :class="[
                        user.hideLink ? null : $style.need_help_user_link,
                        Number(user.flag) > 990 ? $style.inactive : null,
                      ]"
                      v-on:click="clickNeedHelpUser(user)"
                    >
                      <p :class="$style.labelMin">{{ user.grade }}</p>
                      <p>{{ user.username }}</p>
                    </div>
                    <div>
                      <div
                        v-for="answer in user.who5answers"
                        v-bind:key="answer.id"
                      >
                        <p :class="$style.labelMin">{{ formatDate(answer.created_at) }}</p>
                        <p>{{ getWho5AnswerTotal(answer.answers) }} 点</p>
                      </div>
                    </div>
                  </dd>
                </dl>
              </div>
            </loader-simple>
          </div>
        </tamplate>
      </loader-simple>

    </contents-box>

  </div>
</template>

<script>
/** chatrjs */
import { Line } from 'vue-chartjs';
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  CategoryScale,
  LinearScale,
} from 'chart.js';

import { mapState } from 'vuex';
import Spacer from '@/views/components/Spacer.vue';
import BasicBtn from '@/views/components/BasicBtn.vue';
import LoaderSimple from '@/views/components/LoaderSimple.vue';
import ContentsBox from '@/views/components/ContentsBox.vue';
import MonitoringSchedules from '@/views/pages/Common/parts/MonitoringSchedules.vue';
import LabelBox from '@/views/components/LabelBox.vue';
import GuideIcon from '@/views/components/GuideIcon.vue';

import moment from 'moment';

import cf from '@/mixins/commonFunctions.js';

const { cloneDeep } = require('lodash');

const NEED_HELP_LINE = 5; // 要支援者 = who5が5未満と定義

ChartJS.register(Title, Tooltip, Legend, PointElement, LineElement, CategoryScale, LinearScale);

const BILLING_TYPE_1 = 1; // 従量課金モデル(スポットプラン)
const DATE_FORMAT = {
  YMD: 'YYYY-MM-DD',
  MD: 'MM/DD',
  YMDHM: 'YYYY-MM-DD HH:mm',
  YMD4DISP: 'YYYY/MM/DD',
  YMDHM4DISP: 'YYYY/MM/DD HH:mm',
  YM: 'YYYY/MM',
  MM: 'MM',
};
const STENSCORE = {
  threshold: {
    low: 3,
    high: 7,
  },
};
const CATEGORIZE_TYPE = {
  ACADEMIC_YEAR: 'academic_year',
  WHO5ANSWER_TYPE: 'who5answer_type',
  SCHOOL_YEAR: 'school_year',
  UNITS: 'units',
  ACCOUNT_TYPE: 'account_type',
};
const SELECTABLE_ACCOUNT_TYPES = [
  1, // 生徒・プログラム対象者
  3, // 教職員・企業担当者
];
const WHO5ANSWER_TYPE_MONITORING = 4; // モニタリング

// const CHART_POINT = {
//   // 色について
//   // frontend/vue/src/assets/styles/vars/_color.scss より

//   // // orange
//   // --orange-main: #e7a223;
//   // --orange-sub: #f0c26f;
//   // --orange-main-rgb: 231, 162, 35;

//   // // blue
//   // --blue-main: #60b2c1;
//   // --blue-sub: #def6fa;
//   // --blue-main-rgb: 96, 178, 193;

//   // // green
//   // --green-main: #60c192;
//   // --green-sub: #ade1c8;
//   // --green-main-rgb: 96, 193, 146;

//   WHO5ANSWER_TYPE: {
//     1: { // オンライン健康相談
//       // green
//       COLOR: {
//         R: 96,
//         G: 193,
//         B: 146,
//       },
//       STYLE: 'rect',
//     },
//     2: { // テキスト健康相談
//       // blue
//       COLOR: {
//         R: 96,
//         G: 178,
//         B: 193,
//       },
//       STYLE: 'rect',
//     },
//     3: { // バーチャル健康相談
//       // orange
//       COLOR: {
//         R: 231,
//         G: 162,
//         B: 35,
//       },
//       STYLE: 'rect',
//     },
//     4: { // モニタリング
//       //  青
//       COLOR: {
//         R: 100,
//         G: 149,
//         B: 237,
//       },
//       STYLE: 'circle',
//     },
//   },
//   ACCOUNT_TYPE: {
//     1: { // 生徒・プログラム対象者
//       // 赤
//       COLOR: {
//         R: 255,
//         G: 99,
//         B: 132,
//       },
//       STYLE: 'rect',
//     },
//     3: { // 教職員
//       // 黄
//       COLOR: {
//         R: 255,
//         G: 215,
//         B: 0,
//       },
//       STYLE: 'rect',
//     },
//   },
// };

export default {
  name: 'cocomoni-dashboard',
  mixins: [cf],
  components: {
    Line,
    Spacer,
    BasicBtn,
    LoaderSimple,
    ContentsBox,
    MonitoringSchedules,
    LabelBox,
    GuideIcon,
  },
  props: {
    school: { // 個別表示する学校企業情報
      type: Object,
    },
    getAllData: { // すべての学校企業を対象としてデータを取得するかどうか
      type: Boolean,
    },
    getAllTypesOfWho5Answer: { // ダッシュボード上に表示するwho5answerデータに対し、who5answers.typeの制御を外すかどうか
      type: Boolean, // trueを渡された時、who5answers.typeの条件を外して情報取得する
    },
  },
  data() {
    return {
      // グラフ表示の大元となるデータ
      who5AnswersAll: [], // who5answer全データ
      usersSchoolYear: [], // 学年別ユーザ
      unitsWithUserIds: [], // グループ別ユーザ

      displayDates: [], // 直近２週間の日付データ
      kokoroAlerts: [], // ココロアラート用
      // who5Averages: [], // グラフ表示中の月別who5answerデータ
      monitoringAverages: [], // モニタリング平均データ
      // allWho5Averages: {
      //   all: [], // 全データ
      // }, // 月別who5answer（表示可能なすべてのデータ）
      needHelpMonths: [], // 要支援者月間情報（対象月とユーザ）
      needHelpUserTargetMonth: null, // 要支援者:対象月
      needHelpUserDetails: [], // 要支援者対象ユーザ情報
      academicYearList4Monitoring: [], // 検索条件: 年度リスト（モニタリング用）
      academicYearList4CrossAnalytics: [], // 検索条件: 年度リスト（クロス集計用）
      monitoringSchedules: [], // 対象校のモニタリングスケジュール情報
      // 折れ線グラフ用
      lineGraph: {
        // ココモニボイス利用推移
        uranaiVoice: {
          data: {},
          options: {},
        },
        // ココロの健康診断
        kokoroKenkoShindan: {
          data: {},
          options: {},
        },
      },
      // 心の健康相談推移、回答率と要支援者数
      kokoroKenkoShindanTable: { // TODO 
        answerRates: [],
        needHelpUserCounts: [],
      },
      // 検索条件
      searchCondition: {
        uranaiVoice: {
          categorizeType: '',
          categorizeKey: '',
          categorizeKeyOptions: [],
        },
        kokoroKenkoShindan: {
          academicYear: cf.getFiscalYear(new Date()),
          categorizeType: '',
          categorizeKey: '',
          categorizeKeyOptions: [],
          // クロス分析条件
          crossAnalytics: {
            academic_year: [],
            who5answer_type: [],
            school_year: [],
            units: [],
            account_type: [],
          },
        },
      },
      // 検索条件設定
      config: {
        maxSchoolYear: 0,
        units: [], // グループ
      },
      // 画面制御
      flag: {
        showNeedHelpUser: false,
        showMonitoringMainData: true,
        loading: {
          kokoroAlerts: true,
          uranaiVoice: true,
          kokoroKenkoShindan: true,
        },
      },
      targetSchoolUsersAll: [], // 所属全ユーザ（学校指定時のみ取得）
      // 定数
      CATEGORIZE_TYPE,
    };
  },
  created() {
    // 検索条件の選択肢を準備
    this.setMaxSchoolYear(); // 学年
    this.setUnits(); // グループ

    // 指定校のユーザ情報を取得
    this.setTargetSchoolUsersAll();

    // 本日から起算して２週間分の日付データを準備
    this.displayDates = this.getDisplayDates();

    // 画面表示する実データを準備
    this.getKokoroAlertData(); // ココロアラート
    this.getMimosysCounts(); // ココモニボイス利用推移
    this.getKokoroKenkoShindanData(); // ココロの健康診断/回答率/要支援者情報
  },
  computed: {
    ...mapState(['user', 'helper']),
    isOperator() {
      return this.user && [31].includes(this.user.account_type);
    },
    isCompany() {
      return this.school && this.school.is_company;
    },
    accountTypeLabel() {
      if (this.isCompany) {
        return this.helper.master.labels.users.company_account_type;
      }
      return this.helper.master.labels.users.account_type;
    },
    isSpotPlan() {
      // billing_type = 1: 従量課金モデル(スポットプラン)
      return this.school && this.school.billing_type && this.school.billing_type.billing_type === BILLING_TYPE_1;
    },
    schoolLabel() {
      if (this.getAllData) {
        return '';
      }
      return this.isCompany ? '企業' : '学校';
    },
    targetWho5Type() {
      // getAllTypesOfWho5Answerオプションを指定されない場合はtype=4（モニタリング）のみを取得対象とする
      return this.getAllTypesOfWho5Answer ? null : WHO5ANSWER_TYPE_MONITORING;
    },
    answerRates() {
      // 枠を用意（12か月分）
      const rates = Array(12).fill(null);

      // モニタリング以外のデータも取得する場合は回答率は算出不可
      if (this.getAllTypesOfWho5Answer) {
        return rates;
      }

      const denominators = Array(12).fill(null); // 分母

      // 算出
      this.monitoringSchedules.forEach((schedule) => {
        // モニタリング開始時刻がまだ到来していない場合は回答率の算出は不可
        if (!schedule.prop) {
          return;
        }
        // 対象月のモニタリング対象者の母数を決定する
        // MEMO: 現在モニタリングデータに対する絞り込み条件はない
        // const denominator = (() => {
        //   if (this.searchCondition.kokoroKenkoShindan.categorizeType === CATEGORIZE_TYPE.SCHOOL_YEAR) {
        //     // 学年による絞り込み中
        //     return schedule.prop.detail_json.count_by_school_year[this.searchCondition.kokoroKenkoShindan.categorizeKey];
        //   }
        //   if (this.searchCondition.kokoroKenkoShindan.categorizeType === CATEGORIZE_TYPE.UNITS) {
        //     // グループによる絞り込み中
        //     return schedule.prop.detail_json.count_by_unit[this.searchCondition.kokoroKenkoShindan.categorizeKey];
        //   }
        //   // 絞り込みなし = 全対象者数を採用
        //   return schedule.prop.target_user_count;
        // })();
        const denominator = schedule.prop.target_user_count;
        if (!denominator) {
          // 母数が決定できない場合は算出しない
          return;
        }
        // 枠に対する要素番号をスケジュールの開始月から決定する
        const index = (() => {
          const targetMonth = Number(moment(schedule.start_time).format('M'));
          if (targetMonth < 4) {
            // 1月 = index 9, 2月 = index 10, 3月 = index 11
            return targetMonth + 8;
          }
          // 4月 = index 0, 以降1,2,3...
          return targetMonth - 4;
        })();
        // 月ごとの分母を足し込む
        if (denominators[index]) {
          denominators[index] += denominator;
        } else {
          denominators[index] = denominator;
        }
      });

      // 最終結果としての回答率を算出
      rates.forEach((rate, i) => {
        const denominator = denominators[i];
        if (denominator) {
          const numerator = this.monitoringAverages[i].users.length; // 分子
          const rateResult = ((numerator / denominator) * 100).toFixed(2); // 小数点第２位までを表示
          rates[i] = {
            denominator,
            numerator,
            rate: rateResult.replace(/\.0+$/, ''), // 末尾の.0+を空文字に置換
          };
        }
      });
      return rates;
    },
    who5AnswerTypesForCrossAnalytics() {
      // 対象外とするタイプ
      const excludeTypes = [
        4, // モニタリング
      ];
      return Object.entries(this.helper.master.labels.who5answer.type).filter(([key]) => !excludeTypes.includes(Number(key)));
    },
    academicYearMap() {
      if (this.academicYearList4CrossAnalytics.length === 0) {
        return {};
      }
      const map = {};
      this.academicYearList4CrossAnalytics.forEach((year) => {
        const startTime = moment(`${year}-04-01`).format(DATE_FORMAT.YMDHM);
        const endTime = moment(`${year + 1}-03-31 23:59:59`).format(DATE_FORMAT.YMDHM);
        map[year] = {
          start: startTime,
          end: endTime,
        };
      });
      return map;
    },
    crossAnalyticsAccountTypes() {
      if (this.targetSchoolUsersAll.length === 0) {
        return SELECTABLE_ACCOUNT_TYPES;
      }
      return this.targetSchoolUsersAll.map((user) => user.account_type).filter((value, index, self) => self.indexOf(value) === index).sort();
    },
  },
  watch: {
    'flag.showMonitoringMainData': {
      handler() {
        this._getKokoroKenkoShindanDatasets(true);
      },
    },
  },
  methods: {
    // 学校の最大学年をセット
    setMaxSchoolYear() { // copy from UnitEditor.vue
      if (this.getAllData) {
        return;
      }
      if (this.isCompany) {
        return;
      }
      const maxYear = this.helper.master.schoolYear[this.school.school_type].value;
      this.config.maxSchoolYear = maxYear + 2;
    },

    async setUnits() {
      if (this.getAllData) {
        return;
      }
      const params = {
        school_id: this.school.id,
      };
      const response = await this.axios({
        method: 'GET',
        url: '/v1/unit/get/list',
        params,
      });
      const results = response.data.units.data;
      this.config.units = results.filter((unit) => unit.flag !== 999);
    },
    async setTargetSchoolUsersAll() {
      if (this.getAllData || !this.school) {
        return;
      }
      const response = await this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          school_id: this.school.id,
          accountTypes: SELECTABLE_ACCOUNT_TYPES,
          onlyActiveUser: 1, // 有効なユーザーのみ
        },
      });
      this.targetSchoolUsersAll = response.data.users.data;
    },
    async setAcademicYear() {
      const _getYearList = async (type) => {
        const yearList = [];
        const academicYearNow = cf.getFiscalYear(new Date());
        const params = {
          school_id: this.getAllData ? null : this.school.id,
          type,
        };
        const response = await this.axios({
          method: 'GET',
          url: '/v1/who5answer/get/oldestAnswer',
          params,
        });
        const data = response.data.data;
        if (!data) {
          // 過去データが存在しない場合は今年度のみが対象
          yearList.push(academicYearNow);
          return yearList;
        }
        // 過去データが存在する場合は対象年度から今年度までを選択可能にする
        for (let targetYear = cf.getFiscalYear(data.created_at); targetYear <= academicYearNow; targetYear += 1) {
          yearList.push(targetYear);
        }
        return yearList;
      };
      // モニタリングを対象とした設定
      this.academicYearList4Monitoring = await _getYearList(this.targetWho5Type);
      // クロス集計を対象とした設定
      this.academicYearList4CrossAnalytics = await _getYearList();
    },
    getDisplayDates() {
      const ammount = 14;
      const dates = [];
      for (let i = 0; i < ammount; i += 1) {
        const date = moment().subtract(i, 'days').format(DATE_FORMAT.MD);
        dates.push(date);
      }
      dates[0] = '本日';
      return dates.reverse();
    },
    getDisplayDatesISO8601() {
      const ammount = 14;
      const dates = [];
      for (let i = 0; i < ammount; i += 1) {
        const date = moment().subtract(i, 'days').format(DATE_FORMAT.YMD);
        dates.push(date);
      }
      return dates.reverse();
    },
    getDisplayMonths() {
      const aprilIndex = 3; // 4月
      const now = moment();
      const startYear = now.month() < aprilIndex ? now.year() - 1 : now.year();
      const targetMonth = moment({
        year: startYear,
        month: aprilIndex,
      });

      const result = [];
      for (let i = 0; i < 12; i += 1) {
        result.push(targetMonth.format('yyyy/MM'));
        targetMonth.add('months', 1);
      }
      return result;
    },
    async getKokoroAlertData() {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/graph/get/kokoroAlerts',
        params: {
          school_id: this.getAllData ? null : this.school.id,
        },
      });
      const data = response.data.data; // キー:ユーザid, 値:オブジェクト

      const displayDates = this.getDisplayDatesISO8601();
      const userIds = Object.keys(data);
      userIds.forEach((userId) => {
        const targetUserData = data[userId];
        // 下向き矢印のないユーザを取り除く
        if (!targetUserData.stenScores.some((row) => this.isStenScoreLow(row.stenScore))) {
          return;
        }
        // 表示日付分のデータを埋めてあげる & アイコン情報をセットする
        const twoWeeksData = [];
        displayDates.forEach((date) => {
          const sameDateData = targetUserData.stenScores.find((row) => moment(row.created_at).format(DATE_FORMAT.YMD) === date);
          if (sameDateData) {
            const imageData = this.getCocomoniImageData(sameDateData.stenScore);
            twoWeeksData.push(Object.assign(sameDateData, { imageData }));
          } else {
            twoWeeksData.push({}); // データのない日は空オブジェクト
          }
        });
        this.kokoroAlerts.push(Object.assign(targetUserData, {
          stenScores: twoWeeksData,
        }));
      });
      // 学校の場合は学年でソート
      if (!this.isCompany) {
        const noSchoolYear = 100;
        this.kokoroAlerts.sort((a, b) => (a.school_year || noSchoolYear) - (b.school_year || noSchoolYear));
      }

      this.flag.loading.kokoroAlerts = false;
    },
    /** 検索時の呼び分け */
    async getGraphData(prop) {
      if (prop === 'uranaiVoice') {
        this.getMimosysCounts();
      }
      if (prop === 'kokoroKenkoShindan') {
        this.getKokoroKenkoShindanData();
      }
    },
    /** 「ココモニボイス利用推移」データ取得 */
    async getMimosysCounts() {
      // 表示データ取得
      const datasets = [
        // 生徒/プログラム参加者
        {
          label: await this._getDatasetLabel(1), // 生徒/プログラム対象者
          backgroundColor: 'rgba(255, 99, 132, 0.5)', // 塗りつぶしの色 (透明度あり)
          borderColor: 'rgba(255, 99, 132, 1.0)', // 線の色
          data: this._convertUranaiVoiceData(await this._getMimosysCounts({
            school_id: this.getAllData ? null : this.school.id,
            account_type: 1, // 生徒
            categorize_type: this.searchCondition.uranaiVoice.categorizeType,
            categorize_value: this.searchCondition.uranaiVoice.categorizeKey,
          })),
          pointRadius: 10, // ポイントの半径を10pxに設定
        },
      ];
      // それ以外のアカウントタイプについては学校に対してのみ取得を行う
      if (this.getAllData || !this.isCompany) {
        // billing_type = 1: 従量課金モデル(スポットプランの場合、追加で情報取得する
        if (this.getAllData || this.isSpotPlan) {

          // 保護者は表示対象外とする

          // // 保護者（学校の場合のみ）
          // datasets.push({
          //   label: await this._getDatasetLabel(2), // 保護者
          //   backgroundColor: 'rgba(96, 178, 193, 0.5)', // 塗りつぶしの色 (透明度あり)
          //   borderColor: 'rgb(96, 178, 193)', // 線の色
          //   data: this._convertUranaiVoiceData(await this._getMimosysCounts({
          //     school_id: this.getAllData ? null : this.school.id,
          //     account_type: 2, // 保護者
          //     categorize_type: this.searchCondition.uranaiVoice.categorizeType,
          //     categorize_value: this.searchCondition.uranaiVoice.categorizeKey,
          //   })),
          //   pointRadius: 10, // ポイントの半径を10pxに設定
          // });

          // 教職員/企業担当者
          datasets.push({
            label: await this._getDatasetLabel(3), // 教職員
            backgroundColor: 'rgba(255, 215, 0, 0.5)', // 塗りつぶしの色 (透明度あり)
            borderColor: 'rgba(255, 215, 0, 1.0)', // 線の色
            data: this._convertUranaiVoiceData(await this._getMimosysCounts({
              school_id: this.getAllData ? null : this.school.id,
              account_type: 3, // 教職員/企業担当者
              categorize_type: this.searchCondition.uranaiVoice.categorizeType,
              categorize_value: this.searchCondition.uranaiVoice.categorizeKey,
            })),
            pointRadius: 10, // ポイントの半径を10pxに設定
          });
        }
      }

      this.lineGraph.uranaiVoice = {
        data: {
          labels: this.getDisplayDates(),
          datasets,
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              onClick: () => {
                // グラフ上の判例クリック時に何も処理を行わない（指定の線を非表示化しない）
              },
              // 判例のマウスオーバー時の挙動を設定（対象のデータセット以外の透明度を調整）
              onHover: this._chartHandleHover_uranaiVoice,
              onLeave: this._chartHandleLeave_uranaiVoice,
            },
            tooltip: {
              titleFont: {
                size: 18,
              },
              bodyFont: {
                size: 16,
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                stepSize: 1, // 目盛は整数
              },
              // 最大値はデータの最大値+アルファとし、グラフ際上段データと判例が被らないようにする
              max: (() => {
                const max = Math.max(...datasets.map((dataset) => Math.max(...dataset.data)));
                if (max < 10) {
                  return max + 1;
                }
                return max + 5;
              })(),
            },
          },
          spanGaps: true, // データが欠損している場合の挙動: 線を繋げる
        },
      };
    },
    _chartHandleHover_uranaiVoice(evt, item) {
      this.__chartHandleHover(this.lineGraph.uranaiVoice, item);
    },
    _chartHandleLeave_uranaiVoice(evt, item) {
      this.__chartHandleLeave(this.lineGraph.uranaiVoice, item);
    },

    /** 「ココモニボイス利用推移」データ取得 */
    async _getMimosysCounts(params) {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/mimosys/get/mimosysCounts',
        params,
      });
      return response.data.data;
    },

    async _getDatasetLabel(accountType, porpKey = 'uranaiVoice') {
      // 母数の取得
      const response = await this.axios({
        method: 'GET',
        url: '/v1/user/get/userCount',
        params: {
          flags: [
            1,
          ],
          account_types: accountType ? [accountType] : null,
          school_id: this.getAllData ? null : this.school.id,
          categorize_type: this.searchCondition[porpKey].categorizeType,
          categorize_value: this.searchCondition[porpKey].categorizeKey,
        },
      });
      const suffix = `（全${response.data.count}名）`;

      // 全体向けラベル
      if (this.getAllData && accountType === 1) { // 生徒/プログラム対象者
        return `${this.helper.master.labels.users.account_type[accountType]}/${this.helper.master.labels.users.company_account_type[accountType]}${suffix}`;
      }
      // 会社向けラベル
      if (this.isCompany) {
        return `${this.helper.master.labels.users.company_account_type[accountType]}${suffix}`;
      }
      // 学校向けラベル
      return `${this.helper.master.labels.users.account_type[accountType]}${suffix}`;
    },
    _convertUranaiVoiceData(counts) {
      const displayDates = this.getDisplayDatesISO8601();
      const data = [];
      displayDates.forEach((targetDate) => {
        const target = counts.find((row) => row.implementation_date === targetDate);
        data.push(target ? target.count : 0);
      });
      return data;
    },

    async getKokoroKenkoShindanData() {
      this.flag.loading.kokoroKenkoShindan = true;

      this.needHelpUserTargetMonth = null; // 選択状態の要支援者月を解除する
      this.needHelpUserDetails = [];

      // 必要なデータ
      // who5Answersの年間全データ（このデータをクロス集計条件によってfilterしてグラフ表示する）
      await this.getWho5AnswersAll();
      // 対象年度の全ユーザのデータを当時の学年付きで取得（学年データは今年度ならusersテーブル、そうでない場合はreserves/messageGroups/who5Answersに入っている）
      await this.getUsersSchoolYear();
      // グループごとの所属ユーザを取得
      await this.getUnitsWithUserIds(); // TODO これは初期表示時に取得すればあとはキャッシュでいい

      // 年度リストをセット
      await this.setAcademicYear();

      // モニタリングのメインデータ取得
      this.monitoringAverages = await this.getMonitoringAverage();

      // グラフデータの生成
      this.lineGraph.kokoroKenkoShindan = {
        data: {
          labels: (() => {
            const months = this.monitoringAverages.map((row) => row.month);
            return [''].concat(months); // 下部のテーブルとレイアウトを揃えるため、あえて先頭に１目盛追加する
          })(),
          datasets: this._getKokoroKenkoShindanDatasets(),
        },
        options: {
          responsive: true,
          plugins: {
            legend: {
              position: 'top',
              // onClick: () => {
              //   // グラフ上の判例クリック時に何も処理を行わない（指定の線を非表示化しない）
              // },
              // 判例のマウスオーバー時の挙動を設定（対象のデータセット以外の透明度を調整）
              onHover: this._chartHandleHover_kokoroKenkoShindan,
              onLeave: this._chartHandleLeave_kokoroKenkoShindan,
            },
            tooltip: {
              titleFont: {
                size: 18,
              },
              bodyFont: {
                size: 16,
              },
              callbacks: { // ポイントのタイトルとラベルを設定
                title: (context) => {
                  const targetChart = context[0];
                  // index1 = 4月
                  let month = targetChart.dataIndex + 3;
                  let addYear = 0;
                  if (month > 12) {
                    month -= 12;
                    addYear = 1;
                  }
                  // 対象データの年度を採用する
                  const academicYear = targetChart.dataset.academicYear;
                  if (academicYear) {
                    return moment(`${Number(academicYear) + addYear}/${month}/01`).format('YYYY/MM');
                  }
                  return moment(`${Number(moment().format('YYYY') + addYear)}/${month}/01`).format('M月');
                },
                label: (context) => {
                  // 一旦文字列に変換
                  const label = `${context.dataset.data[context.dataIndex]}`;
                  // 小数点がない場合、'.0'を末尾につけて返却
                  let dispNum;
                  if (label.indexOf('.') === -1) {
                    dispNum = `${label}.0`;
                  } else {
                    // 小数点がある場合、そのまま
                    dispNum = label;
                  }
                  // ココロの健康診断の場合のみ、先頭にラベルを付加する
                  if (context.dataset.label === 'ココロの健康診断') {
                    return `平均値: ${dispNum}`;
                  }
                  return dispNum;
                },
              },
            },
          },
          scales: {
            y: {
              min: -1.1, // 0点の場合にポイントが見づらくなることへの対処
              max: 26.1, // 25点の場合にポイントが見づらくなることへの対処
              ticks: {
                callback: (value) => ([0, 5, 10, 15, 20, 25].includes(value) ? value : null), // y軸目盛の明示指定
              },
            },
          },
          spanGaps: true, // データが欠損している場合の挙動: 線を繋げる
        },
      };

      // 回答率
      try {
        const response = await this.axios({
          method: 'GET',
          url: '/v1/monitoringSchedule/get/list',
          params: {
            school_id: this.getAllData ? null : this.school.id,
            type: 1, // モニタリング
            academic_year: this.searchCondition.kokoroKenkoShindan.academicYear,
            with_prop: 1,
          },
        });
        this.monitoringSchedules = response.data.data;
        // あとはcomputedで算出
      } catch (e) {
        alert('モニタリングスケジュール情報の取得に失敗しました');
        throw e;
      }

      // 要支援者情報の生成
      const needHelpMonths = [];
      this.monitoringAverages.forEach((data) => {
        const targetUsers = [];
        needHelpMonths.push({
          month: data.month,
          users: targetUsers,
        });
        data.users.forEach((user) => {
          for (let i = 0; i < user.row.length; i += 1) {
            // who5スコア算出
            let score = 0;
            (JSON.parse(user.row[i].answers)).forEach((answer) => {
              score += answer;
            });
            // 閾値を下回っているアカウントを要支援者としてセット
            if (score < NEED_HELP_LINE) {
              targetUsers.push(user);
              break;
            }
          }
        });
      });
      this.needHelpMonths = needHelpMonths;


      this.flag.loading.kokoroKenkoShindan = false;
    },
    _chartHandleHover_kokoroKenkoShindan(evt, item) {
      this.__chartHandleHover(this.lineGraph.kokoroKenkoShindan, item);
    },
    _chartHandleLeave_kokoroKenkoShindan(evt, item) {
      this.__chartHandleLeave(this.lineGraph.kokoroKenkoShindan, item);
    },
    _getKokoroKenkoShindanDatasets(isSetDirect = false) {
      const datasets = [];

      const _getDatasetProp = ({
        who5Averages = [], // who5Averagesデータ（月毎、1年間のデータ）
        who5AnswerType = null, // who5answer.type
        label = null, // ラベル
        academicYear = null, // データの対象年度
        backgroundColor = null, // 塗りつぶしの色 (透明度あり)
        borderColor = null, // 線の色
      }) => {
        // データセットのプロパティを返却
        const datasetProp = {
          label,
          academicYear, // データの対象年度
          backgroundColor, // 塗りつぶしの色 (透明度あり)
          borderColor, // 線の色
          data: (() => {
            const dataList = who5Averages.map((row) => row.scoreAverage);
            return [null].concat(dataList); // 下部のテーブルとレイアウトを揃えるため、あえて先頭に１目盛追加する
          })(),
          pointRadius: 10, // ポイントの半径を10pxに設定
          pointStyle: Number(who5AnswerType) === WHO5ANSWER_TYPE_MONITORING ? 'circle' : 'rect', // ポイントの形状
        };
        return datasetProp;
      };

      // メインデータをセット
      if (this.flag.showMonitoringMainData) {
        datasets.push(_getDatasetProp({
          who5Averages: this.monitoringAverages,
          who5AnswerType: WHO5ANSWER_TYPE_MONITORING,
          label: 'ココロの健康診断',
          academicYear: this.searchCondition.kokoroKenkoShindan.academicYear,
          backgroundColor: 'rgba(100, 149, 237, 0.5)', // 塗りつぶしの色 (透明度あり)
          borderColor: 'rgba(30, 144, 255, 1.0)', // 線の色
        }));
      }

      // 以下、比較対象として指定されたデータを設定

      // 画面上で選択された「相談方法」「学年」「グループ」「アカウントタイプ」の総組合せ数だけグラフを生成する
      // まずは組合せを表す配列を生成
      const academicYears = this.searchCondition.kokoroKenkoShindan.crossAnalytics.academic_year.length === 0
        ? ['']
        : cloneDeep(this.searchCondition.kokoroKenkoShindan.crossAnalytics.academic_year).sort(); // 画面上で選択した順序ではなく、年度順にソート
      const who5AnswerTypes = this.searchCondition.kokoroKenkoShindan.crossAnalytics.who5answer_type.length === 0
        ? ['']
        : cloneDeep(this.searchCondition.kokoroKenkoShindan.crossAnalytics.who5answer_type).sort(); // 画面上で選択した順序ではなく、ID順にソート
      const schoolYears = this.searchCondition.kokoroKenkoShindan.crossAnalytics.school_year.length === 0
        ? ['']
        : cloneDeep(this.searchCondition.kokoroKenkoShindan.crossAnalytics.school_year).sort(); // 画面上で選択した順序ではなく、ID順にソート
      const units = this.searchCondition.kokoroKenkoShindan.crossAnalytics.units.length === 0
        ? ['']
        : cloneDeep(this.searchCondition.kokoroKenkoShindan.crossAnalytics.units).sort(); // 画面上で選択した順序ではなく、ID順にソート
      const accountTypes = this.searchCondition.kokoroKenkoShindan.crossAnalytics.account_type.length === 0
        ? ['']
        : cloneDeep(this.searchCondition.kokoroKenkoShindan.crossAnalytics.account_type).sort(); // 画面上で選択した順序ではなく、ID順にソート
      const keyConcats = [];
      const separator = '-';
      academicYears.forEach((academicYear) => {
        who5AnswerTypes.forEach((who5AnswerType) => {
          schoolYears.forEach((schoolYear) => {
            units.forEach((unit) => {
              accountTypes.forEach((accountType) => {
                keyConcats.push(`${academicYear}${separator}${who5AnswerType}${separator}${schoolYear}${separator}${unit}${separator}${accountType}`);
              });
            });
          });
        });
      });

      // const filterMethodName = {
      //   0: CATEGORIZE_TYPE.ACADEMIC_YEAR,
      //   1: CATEGORIZE_TYPE.WHO5ANSWER_TYPE,
      //   2: CATEGORIZE_TYPE.SCHOOL_YEAR,
      //   3: CATEGORIZE_TYPE.UNITS,
      //   4: CATEGORIZE_TYPE.ACCOUNT_TYPE,
      // };

      // filterメソッド
      const _filter = [
        // [0]: academic_year
        (who5Answers, academicYear) => who5Answers.filter((row) => {
          const startTime = this.academicYearMap[academicYear].start;
          const endTime = this.academicYearMap[academicYear].end;
          // 同日同時刻を含む場合はtrueを返す
          return moment(row.created_at).isBetween(startTime, endTime, null, '[]');
        }),
        // [1]: who5answer_type
        (who5Answers, type) => who5Answers.filter((row) => row.type === Number(type)),
        // [2]: school_year
        (who5Answers, schoolYear) => {
          const result = who5Answers.filter((row) => {
            const targetUser = this.usersSchoolYear.find((user) => user.id === row.user_id);
            if (targetUser) {
              return targetUser.school_year === Number(schoolYear);
            }
            return false;
          });
          return result;
        },
        // [3]: units
        (who5Answers, unitId) => {
          const result = who5Answers.filter((row) => {
            const targetUnit = this.unitsWithUserIds.find((unit) => unit.id === Number(unitId));
            if (targetUnit) {
              return targetUnit.userIds.includes(row.user_id);
            }
            return false;
          });
          return result;
        },
        // [4]: account_type
        (who5Answers, accountType) => {
          const result = who5Answers.filter((row) => {
            const targetUser = this.usersSchoolYear.find((user) => user.id === row.user_id);
            if (targetUser) {
              return targetUser.account_type === Number(accountType);
            }
            return false;
          });
          return result;
        },
      ];

      // 以下、組合せごとのデータを生成
      // ここで生成したデータをdatasetsに追加する
      const graphData = [];
      keyConcats.forEach((keyConcat) => {
        let filteredData = cloneDeep(this.who5AnswersAll);
        
        // ココロの健康診断回答データを除外（クロス集計で表示するデータとしては各種健康相談データのみを採用する）
        filteredData = filteredData.filter((row) => row.type !== WHO5ANSWER_TYPE_MONITORING);

        const keys = keyConcat.split(separator);
        // どれか一つでも選択されていない場合はスキップ
        if (!keys[0] && !keys[1] && !keys[2] && !keys[3] && !keys[4]) {
          return;
        }
        // それぞれのfilterを適用
        keys.forEach((key, i) => {
          if (key) {
            filteredData = _filter[i](filteredData, key);
          }
        });

        // datasetsに詰めていく
        graphData.push({
          key: keyConcat,
          who5AnswerType: keys[1], // クロス集計条件によってはnullの場合もありうる
          label: (() => {
            // ラベルを生成
            // 形式: 'who5answer_type x 学年 x グループ'
            const labels = [];
            if (keys[0]) {
              labels.push(`${keys[0]}年度`);
            }
            if (keys[1]) {
              labels.push(this.helper.master.labels.who5answer.type[keys[1]]);
            }
            if (keys[2]) {
              labels.push(`${keys[2]}年生`);
            }
            if (keys[3]) {
              const targetUnit = this.config.units.find((unit) => unit.id === Number(keys[3]));
              if (targetUnit) {
                labels.push(targetUnit.label);
              }
            }
            if (keys[4]) {
              labels.push(this.accountTypeLabel[keys[4]]);
            }
            return labels.join(' x ');
          })(),
          // データを次のルールで生成
          // 配列要素0 = ４月のデータ, 配列要素1 = ５月のデータ, ... , 要素9 = 翌年１月, 要素10 = 翌年２月, 要素11 = 翌年３月 のデータ
          monthDataList: (() => {
            const maxIndex = 11; // 12か月分
            const baseDate = (() => {
              // クロス集計条件で年度を指定した場合
              if (keys[0]) {
                return moment(`${keys[0]}-04-01`); // 指定年度の４月１日
              }
              return moment(`${this.searchCondition.kokoroKenkoShindan.academicYear}-04-01`); // 今年度の４月１日
            })();
            const dataList = []; // 12か月分のデータを格納する
            // まずは空のデータを生成
            for (let i = 0; i <= maxIndex; i += 1) {
              dataList.push({
                month: baseDate.clone().add(i, 'months').format(DATE_FORMAT.YM),
                users: [
                  // {
                  //   user_id: null,
                  //   row: [],
                  // },
                ],
                scoreAverage: null,
              });
            }
            // 月ごとに振り分ける
            filteredData.forEach((row) => {
              const targetMonthOnly = moment(row.created_at).format(DATE_FORMAT.MM); // 月のみで比較∵クロス集計条件で年度を指定していない場合は異年度のデータも含める必要がある
              const targetIndex = dataList.findIndex((rowInner) => {
                const dataMonth = rowInner.month.split('/')[1]; // YYYY/MM の MM部分
                return dataMonth === targetMonthOnly;
              });
              if (targetIndex !== -1) {
                const targetUserData = dataList[targetIndex].users.find((rowInner) => rowInner.user_id === row.user_id);
                if (!targetUserData) {
                  dataList[targetIndex].users.push({
                    user_id: row.user_id,
                    row: [row],
                  });
                } else {
                  targetUserData.row.push(row);
                }
              }
            });
            // scoreAverageを算出
            dataList.forEach((monthData) => {
              if (monthData.users.length === 0) {
                return;
              }
              // users配下のrowのanswersを合計してscoreAverageを算出
              let scoreSum = 0; // 合計スコア
              let totalAnswerCount = 0; // 総回答数
              monthData.users.forEach((user) => {
                totalAnswerCount += user.row.length;
                user.row.forEach((row) => {
                  const answers = JSON.parse(row.answers);
                  answers.forEach((answer) => {
                    scoreSum += answer;
                  });
                });
              });
              monthData.scoreAverage = (scoreSum / totalAnswerCount).toFixed(2);
            });
            return dataList;
          })(),
        });
      });

      // 色の決定メソッド
      const _getColor = (index = 0, isTransparency = false) => {
        // indexに応じて視認しやすい異なる7色で順番に色を返す
        const transparency = isTransparency ? '0.5' : '1.0';
        const colors = [
          '255, 99, 132', // 赤
          '54, 162, 235', // 青
          '255, 206, 86', // 黄
          '75, 192, 192', // 緑
          '153, 102, 255', // 紫
          '255, 159, 64', // オレンジ
          '205, 133, 63', // 茶
        ];
        return `rgba(${colors[index % colors.length]}, ${transparency})`;

        // CHART_POINTを利用する
        // const _getColor = (keys = [], isTransparency = false) => {
        //   const transparency = isTransparency ? '0.5' : '1.0';
        //   if (keys[0]) {
        //     // who5answer_typeが指定されている場合
        //     const target = CHART_POINT.WHO5ANSWER_TYPE;
        //     return `rgba(${target[keys[0]].COLOR.R}, ${target[keys[0]].COLOR.G}, ${target[keys[0]].COLOR.B}, ${transparency})`;
        //   }
        //   if (keys[3]) {
        //     // account_typeが指定されている場合
        //     const target = CHART_POINT.ACCOUNT_TYPE;
        //     return `rgba(${target[keys[3]].COLOR.R}, ${target[keys[3]].COLOR.G}, ${target[keys[3]].COLOR.B}, ${transparency})`;
        //   }
        //   // 上記以外のデフォルト色
        //   return `rgba(205, 133, 63, ${transparency})`;
        // };
      };

      // datasetsに詰めて表示する
      graphData.forEach((data, i) => {
        datasets.push(_getDatasetProp({
          who5Averages: data.monthDataList,
          who5AnswerType: data.who5AnswerType,
          label: data.label,
          academicYear: data.key.split(separator)[0], // クロス集計条件によってはnullの場合もありうる
          // 色指定
          backgroundColor: _getColor(i, true), // 塗りつぶしの色 (透明度あり)
          borderColor: _getColor(i), // 線の色
        }));
      });

      // 最終調整
      // グラフ上には平均点を小数点第1位まで表示する
      datasets.forEach((dataset) => {
        dataset.data = dataset.data.map((score) => (score === null ? null : parseFloat(score).toFixed(1)));
      });

      if (isSetDirect) {
        this.lineGraph.kokoroKenkoShindan.data.datasets = datasets;
      } else {
        return datasets;
      }
    },

    // ココロの健康診断（モニタリング）平均を取得（これが基調のグラフとなる）
    async getMonitoringAverage() {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/graph/get/who5Averages',
        params: {
          school_id: this.getAllData ? null : this.school.id,
          type: this.targetWho5Type,
          academic_year: this.searchCondition.kokoroKenkoShindan.academicYear,
        },
      });
      return response.data.data;
    },

    changeSearchCategoryType(prop) {
      const targetProp = this.searchCondition[prop];
      const optionTop = {
        label: '選択してください',
        value: '',
      };
      targetProp.categorizeKey = ''; // 現在の選択値をクリアする
      // 学年
      if (targetProp.categorizeType === CATEGORIZE_TYPE.SCHOOL_YEAR) {
        targetProp.categorizeKeyOptions = [optionTop];
        for (let i = 1; i <= this.config.maxSchoolYear; i += 1) {
          targetProp.categorizeKeyOptions.push({
            label: `${i}年生`,
            value: i,
          });
        }
        return;
      }
      // グループ
      if (targetProp.categorizeType === CATEGORIZE_TYPE.UNITS) {
        targetProp.categorizeKeyOptions = [optionTop];
        this.config.units.forEach((unit) => {
          targetProp.categorizeKeyOptions.push({
            label: unit.label,
            value: unit.id,
          });
        });
        return;
      }
      // 全体
      targetProp.categorizeKeyOptions = [];
      this.getGraphData(prop); // 全体切り替えの場合は検索まで実行する
    },
    changeSearchCategoryKey(prop) {
      this.getGraphData(prop);
    },

    // stenscore決定
    isStenScoreLow(stenScore) {
      return stenScore < STENSCORE.threshold.low;
    },
    isStenScoreHigh(stenScore) {
      return stenScore >= STENSCORE.threshold.high;
    },
    // ココモニの矢印画像を取得する
    getCocomoniImageData(stenScore) {
      if (this.isStenScoreHigh(stenScore)) {
        return {
          text: 'High',
          src: '/img/default/arrow_up.png',
          alt: '上向き矢印',
        };
      }
      if (this.isStenScoreLow(stenScore)) {
        return {
          text: 'Low',
          src: '/img/default/arrow_down.png',
          alt: '下向き矢印',
        };
      }
      return {
        text: 'Middle',
        src: '/img/default/arrow_flat.png',
        alt: '横ばい矢印',
      };
    },
    // 対象月の要早期対象者を一覧表示する
    async clickNeedHelpUserCell(targetMonthData) {
      // 対象月の対象者情報を取得する
      if (targetMonthData.users.length === 0) {
        return;
      }
      this.flag.loading.needHelpUserDetails = true;
      this.needHelpUserTargetMonth = targetMonthData.month;
      const userIds = targetMonthData.users.map((user) => user.user_id);
      const response = await this.axios({
        method: 'GET',
        url: '/v1/user/get/list',
        params: {
          ids: userIds,
          includeDeleted: 1, // 削除済ユーザも取得
        },
      });
      this.needHelpUserDetails = response.data.users.data.map((userDetail) => {
        const grade = this._getGrade(userDetail);
        return {
          id: userDetail.id,
          username: userDetail.username,
          flag: userDetail.flag,
          grade,
          who5answers: [],
          school: userDetail.school ? userDetail.school[0] : null,
        };
      });
      // 各ユーザのwho5answerを取得する
      Promise.all(this.needHelpUserDetails.map(async (user) => {
        const responseAnsd = await this.axios({
          method: 'GET',
          url: '/v1/who5answer/get/list',
          params: {
            user_id: user.id,
            // targetMonthData.month = 'YYYY/MM' の形式。firefoxだとそのままmomentにかけることができないため、'YYYY/MM/01' に変換してからmomentにかける
            end_at: moment(`${targetMonthData.month}/01`).endOf('month').format(DATE_FORMAT.YMD), // moment.jsで月末日を取得し、end_atにセット
            limit: 6, // 最大6件まで取得
            fetch_by_last: 1, // 最新のデータから取得
          },
        });
        user.who5answers = responseAnsd.data.who5answers.reverse(); // 昇順に並び替え
      }));
      // 学校の場合は学年でソート
      if (!this.isCompany) {
        this.needHelpUserDetails.sort((a, b) => {
          if (a.grade < b.grade) {
            return -1;
          }
          return 1;
        });
      }
      this.flag.loading.needHelpUserDetails = false;
    },
    // 肩書を取得する
    _getGrade(user) {
      // TODO getAllDataの場合に適切な肩書を取得する
      if (this.isCompany || this.getAllData) {
        return null;
      }
      if (user.account_type === 1 && user.school_year) {
        return `${user.school_year}年生`;
      }
      return this.helper.master.labels.users.account_type[user.account_type];
    },
    // ココモニ詳細を別タブで開く
    async clickKokoroAlertUser(userId) {
      window.open(this.$router.resolve({
        path: this.isOperator ? `/operator/cocomoni/${userId}` : `/teacher/cocomoni/${userId}`,
      }).href, '_blank');
    },
    // モーダルで個人チャートを表示する（要支援者起点）
    clickNeedHelpUser(user) {
      if (user.hideLink) {
        return;
      }
      this.showUserChart(user);
    },
    showUserChart(user = null) {
      if (!this.school && !user) {
        return; // どちらも指定されていない場合は何もしない
      }
      const args = {
        modalName: 'user-chart-who5',
        data: {
          school: this.school || user.school,
          user,
        },
      };
      this.$store.dispatch('modal/contents/showModal', args, { root: true });
    },
    formatDate(date) {
      return moment(date).format(DATE_FORMAT.YMD4DISP);
    },
    getWho5AnswerTotal(answers) {
      let total = 0;
      JSON.parse(answers).forEach((answer) => {
        total += answer;
      });
      return total;
    },
    // getPropKey(category, keyVaue) {
    //   return `${category}|${keyVaue}`;
    // },
    // getPropLabel(propKey) {
    //   if (!propKey) {
    //     return null;
    //   }
    //   const [category, keyVaue] = propKey.split('|');
    //   if (category === CATEGORIZE_TYPE.SCHOOL_YEAR) {
    //     return `${keyVaue}年生`;
    //   }
    //   if (category === CATEGORIZE_TYPE.UNITS) {
    //     return this.config.units.find((unit) => unit.id === keyVaue).name;
    //   }
    //   if (category === CATEGORIZE_TYPE.ACCOUNT_TYPE) {
    //     return this.config.units.find((unit) => unit.id === keyVaue).name;
    //   }
    //   return null;
    // },

    // /**
    //  * グラフの色を取得
    //  * @param {number} value 値
    //  * @param {number} min 最小値
    //  * @param {number} max 最大値
    //  * @param {number} baseRed 赤の基準値
    //  * @param {number} baseGreen 緑の基準値
    //  * @param {number} baseBlue 青の基準値
    //  * @param {number} transparency 透明度
    //  */
    // getColor(value, min, max, baseRed, baseGreen, baseBlue, transparency) {
    //   // 値を0～1に正規化
    //   const normalizedValue = (value - min) / (max - min);

    //   // RGB値を計算 (例: 赤と青のグラデーション)
    //   const red = baseRed + normalizedValue * (255 - baseRed);
    //   const green = baseGreen;
    //   const blue = baseBlue - normalizedValue * baseBlue;

    //   return transparency ? `rgba(${Math.round(red)}, ${Math.round(green)}, ${Math.round(blue)}), ${transparency})` : `rgb(${Math.round(red)}, ${Math.round(green)}, ${Math.round(blue)})`;
    // },

    async getWho5AnswersAll() {
      // const startAt = `${this.searchCondition.kokoroKenkoShindan.academicYear}-04-01 00:00:00`; // 指定年度の開始日
      // const endAt = `${Number(this.searchCondition.kokoroKenkoShindan.academicYear) + 1}-03-31 23:59:59`; // 指定年度の終了日
      const response = await this.axios({
        method: 'GET',
        url: '/v1/who5answer/get/list',
        params: {
          school_id: this.getAllData ? null : this.school.id,
          // MEMO: 全期間のデータを取得するため、下記の条件は指定しない
          // start_at: startAt,
          // end_at: endAt,
        },
      });
      this.who5AnswersAll = response.data.who5answers;
    },
    async getUsersSchoolYear() {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/user/get/usersSchoolYear',
        params: {
          academic_year: this.searchCondition.kokoroKenkoShindan.academicYear,
          school_id: this.getAllData ? null : this.school.id,
        },
      });
      this.usersSchoolYear = response.data.data;
    },

    // TODO 初期取得後は再取得の必要なし（検索条件によって左右されない）
    async getUnitsWithUserIds() {
      const response = await this.axios({
        method: 'GET',
        url: '/v1/unit/get/listWithUserIds',
        params: {
          school_id: this.getAllData ? null : this.school.id,
          flag: 1, // 有効なグループのみ
        },
      });
      this.unitsWithUserIds = response.data.units;
    },

    // 「指定なし」にチェックを入れたときに他のチェックを外す
    clickAllCrossAnalytics(e, category) {
      const checked = e.target.checked;
      if (checked) {
        this.searchCondition.kokoroKenkoShindan.crossAnalytics[category] = [];
      }
      // 表示グラフを更新
      this._getKokoroKenkoShindanDatasets(true);
    },
    clickCrossAnalytics() {
      // 表示グラフを更新
      this._getKokoroKenkoShindanDatasets(true);
    },

    /** チャート凡例ホバー対応 */
    // @see https://www.chartjs.org/docs/latest/samples/legend/events.html
    __chartHandleHover(chart, item) {
      chart.data.datasets.forEach((dataset, i) => {
        if (i === item.datasetIndex) {
          return;
        }
        // 透明度を改変
        dataset.backgroundColor = dataset.backgroundColor.replace(/, 0.5\)/, ', 0.025)');
        dataset.borderColor = dataset.borderColor.replace(/, 1.0\)/, ', 0.05)');
      });
    },
    __chartHandleLeave(chart, item) {
      chart.data.datasets.forEach((dataset, i) => {
        if (i === item.datasetIndex) {
          return;
        }
        // 透明度を元に戻す
        dataset.backgroundColor = dataset.backgroundColor.replace(/, 0.025\)/, ', 0.5)');
        dataset.borderColor = dataset.borderColor.replace(/, 0.05\)/, ', 1.0)');
      });
    },
  },
};
</script>

<style lang="scss" module>
th {
  font-size: 14px;
}

.cocomoni_tbl {
  border-collapse: collapse;
  th {
    padding: 0 6.5px;
  }
  td {
    border-bottom: 1px solid var(--gray-sub);
    .grade {
      margin-right: 1rem;
    }
    > * {
      display: block;
      margin: 0 auto;
      text-align: center;
    }
  }
  img {
    width: 24px;
    font-size: 20px;
  }
}

.searchArea {
  flex: 1;
  display: flex;
  align-items: center;
  .select {
    display: flex;
    > dt {
      width: 9em;
    }
    > dd {
      margin-inline-start: 0;
      align-items: center;
    }
    &_box {
      width: 7rem;
      padding: 13px;
      background-color: var(--gray-sub);
      border: none;
      outline: none;
      border-radius: 8px;
      appearance: none;

      &_wrap {
        display: flex;

        li {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }
      }
    }
  }
  .input {
    outline: none;
    border: none;
    background-color: var(--gray-sub);
    min-width: 75%;
    padding: 15px;
    border-radius: 45px;
  }
  .search_wrap {
    display: flex;
    .search_word {
      flex: 8.5;
    }
    .search_button {
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 1.5;
    }
  }
  .right {
    margin-left: auto;
  }
}


.kokoro_counseling_summary {
  --left-space: 35px;
  --right-space: 15px;
  @media screen and (max-width:716px) {
    --right-space: 0px;
  }
  margin-left: var(--left-space);
  margin-right: var(--right-space);
  table {
    width: 100%;
    border-collapse: collapse;
    td {
      width: calc(100% / 12);
      border: 1px solid;
      text-align: right;
      &.selectedCell {
        background-color: rgba(#DD0000, .1);
        font-weight: bold;
      }
      > p {
        font-size: 10px;
      }
    }
  }
  > p {
    font-size: 12px;
  }
}
.kokoro_alert_user, .need_help_user_link {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.need_help_user_row {
  dd {
    margin-left: 0;
    width: 100%;
    display: flex;
    border-bottom: 1px solid var(--gray-main);
    > :not(:first-child) {
      margin-left: 1rem;
    }

    // 子要素は下詰め
    > div {
      margin-top: auto;
    }

    --username-space: 200px;
    @media screen and (max-width:716px) {
      --username-space: 150px;
    }
    // 指名欄
    > :nth-child(1) {
      width: var(--username-space);
      word-break: break-all;
      padding-left: .5rem;
    }
    // スコア欄
    > :nth-child(2) {
      width: calc(100% - var(--username-space));
      display: flex;
      text-align: right;
      > div {
        width: 100px;
      }
    }
  }
}

.select_box {
  width: inherit !important;
  &.labelWidth {
    width: 150px !important;
  }
}
.separator {
  width: 1px;
  height: 40px;
  background-color: var(--gray-main);
  display: inline-block;
  margin: 0 1rem;
}

.dashboardContentsTitle {
  font-size: 20px;
}
.label {
  width: 150px;
  min-width: 150px;
  &_max {
    width: 100%;
  }
}
.labelMin {
  font-size: 10px;
}
.radioWrapper {
  display: flex;
}

.detail {
  li {
    display: flex;
    align-items: flex-start;
    .label {
      width: 150px;
    }
    .content {
      margin-left: 20px;
      margin-top: 3px;
      flex: 1;
      word-break: break-all;
    }
    &:not(:last-child) {
      margin-bottom: .5rem;
    }
  }
  @include sm-view {
    font-size: 14px;
    li {
      display: block;
      .label {
        width: 100%;
        margin: 0 0 .5em;
        text-align: left;
        padding: .5em;
      }
    }
  }
}
.width100 {
  width: 100%;
}
.flexRow {
  display: flex;
  flex-wrap: wrap; // 幅が狭くなった際は折り返す
  align-items: flex-start; // 均等に配置した要素ごとの配置は左寄せ
  // 子要素のdivのmarginを.5remに設定（先頭要素以外）
  > div {
    margin-right: .5rem;
    &:last-child {
      margin-right: 0;
    }
  }
}

.bold {
  font-weight: bold;
  font-size: 20px;
  margin-right: .25rem;
}

.titleIcon {
  margin-right: 10px;
  font-size: 25px;
  line-height: 1;

  @include sm-view {
    font-size: 20px;
    margin-right: 7px;
  }
}

.flex {
  display: flex;
}

.inactive {
  background-color: var(--gray-main);
}

.userNum {
  font-size: 30px;
  font: bold;
}
</style>
